.global-dropdown {
  user-select: none;
  position: relative;
}

.global-dropdown-header:hover, .global-dropdown-header.active { 
  border: 1px solid #64d2ff;
  transition: 0.3s ease border;
}

.global-dropdown-header span {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: left;
  white-space: nowrap;
}

.global-dropdown-header {
  background-color: #0e1013;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  font-size: 0.9rem;
  border: 1px solid #444;
  transition: 0.3s ease border;
  font-weight: 500;
  min-width: 150px;
  border-radius: 5px;
  height: 25px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.global-dropdown-header.reverse {
  flex-direction: row-reverse;
}

.global-dropdown-list {
  position: absolute;
  margin: 8px 0px;
  background-color: #0d0d12;
  color: #fff;
  padding: 8px;
  z-index: 2000000;
  right: 0;
  max-height: 200px;
  flex: 1;
  min-width: 95%;
  width: 97%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow-y: auto;
  /* box-shadow: 5px 10px 20px 5px #272B3080 inset; */
  overflow-x: hidden;
  border: 1px solid #81b5c9;
  scrollbar-width: thin;  
  scrollbar-color: #333 transparent;  
  border-radius: 4px;
}

.global-dropdown.drop-up .global-dropdown-list {
  bottom: 100%;
  top: auto;
  border-radius: 4px;
}

.global-dropdown-item {
  padding: 0px 10px;
  display: flex;
  border-radius: 4px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  cursor: pointer;
  flex: 1;
  text-align: left;
  transition: background-color 0.2s;
  color: #fff;
}

.global-dropdown-item span {
  text-overflow: ellipsis;
  flex: 1;
  padding: 10px 0px;
  overflow: hidden;
  white-space: nowrap;
}

.sub-option--wrapper:hover {
  background-color: #34495e;
  transition: background-color 0.3s ease;
}

.sub-option--wrapper {
  border: none;
  background-color: #333;
  transition: background-color 0.3s ease;
  padding: 3px 5px;
  color: #64d2ff;
  z-index: 10001;
  border-radius: 4px;
  cursor: pointer;
}

.sub-option--wrapper i {
  color: #64d2ff;
  font-size: 18px;
}

.global-dropdown-suboptions {
  padding: 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #fff;
  margin-left: 20px;
  border-left: 1px solid #ccc;
}

.global-dropdown-item:hover, .global-dropdown-item.selected  {
  background-color: #34495e;
  color: #fff;
}

.global-dropdown-header i {
  color: #64d2ff;
}

/* Chrome, Edge, and Safari */
.global-dropdown-list::-webkit-scrollbar {
  width: 8px;  /* scrollbar width */
}

.global-dropdown-list::-webkit-scrollbar-track {
  background: #222;  /* scrollbar track color */
}

.global-dropdown-list::-webkit-scrollbar-thumb {
  background-color: #64d2ff;  /* scrollbar thumb color */
  border-radius: 4px;
  border: 3px solid #222;  /* Creates padding around the scroll thumb */
  transition: background-color 0.3s ease-in-out;  /* Smooth transition for background color */
}

.global-dropdown-list::-webkit-scrollbar-thumb:hover > .global-dropdown-list {
  scrollbar-color: #fff #222;  /* thumb and track color */
}
