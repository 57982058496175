.payment-methods {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 450px;
    padding: 10px;
}

.payment-card {
    padding: 10px;
    border-radius: 10px;
    background-color: #222;
    border: 2px solid #222;
    transition: 0.3s ease border;
    cursor: pointer;
    flex: 1;
    /* width: 90px; */
    max-width: 120px;
    gap: 10px
}

.payment-card:hover {
    border: 2px solid #64d2ff;
    transition: 0.3s ease border;
}

.payment-card.payment-card-selected {
    background-color: #444;
    border: 2px solid #64d2ff;
    transition: 0.3s ease border;
}

.account-info-container{
    min-width: 300px;
    min-height: 100px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    max-height: 500px;
}

.card-input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;
}
.card-input-container input{
    border: 1px solid #ccc;
    width: 97%;
    padding: 10px 5px;
    border-radius: 10px;
}

.card-input-container-input{
    border: 1px solid #ccc;
    width: 97%;
    padding: 10px 5px;
    border-radius: 10px;
    background-color: transparent;
    color: #fff
}

.card-input-container-input.focused{
    border: 2px solid #64d2ff;
}

.currency-preference{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
