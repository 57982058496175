html::-webkit-scrollbar, body::-webkit-scrollbar {
    display: none; 
  }
  
 body {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    overflow: hidden; 
  }

  p, span, input, textarea, button, label, li {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-style: normal;
      line-height: 1.5; 
      resize: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Mulish", sans-serif;
  }

  /* Autofill styling for Firefox */
input:-moz-autofill {
  box-shadow: 0 0 0 30px transparent inset !important;
  -moz-text-fill-color: #fff !important;
}

/* Autofill styling for Edge */
input:-ms-autofill {
  box-shadow: 0 0 0 30px transparent inset !important;
  -ms-text-fill-color: #fff !important;
}

/* Ensure transition remains smooth */
input:-webkit-autofill,
input:-moz-autofill,
input:-ms-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

.non-access {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  color: #ccc;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: 'Mulish';
}

.primary-button {
  padding: 3px 16px;
  color: #fff;
  background-color: #33699f;
  border: none;
  width: fit-content;
  cursor: pointer;
  font-size: 0.9rem;  
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  transition: 0.3s ease all;
}

.primary-button:hover {
  transition: 0.3s ease background-color;
  background-color: #3582d0;
}

.primary-button.disabled, .secondary-button.disabled, .destructive-button.disabled, .destructive-button.disabled:hover, .primary-button.disabled:hover, .secondary-button.disabled:hover  {
  background-color: #666;
  cursor: not-allowed;
  color: #aaa;
}

.secondary-button {
  padding: 3px 16px;
  color: #fff;
  background-color: #0a4076;
  border: none;
  width: fit-content;
  cursor: pointer;
  font-size: 0.9rem;  
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  transition: 0.3s ease all;
}

.secondary-button:hover {
  transition: 0.3s ease background-color;
  background-color: #08539e;
}

.destructive-button {
  padding: 3px 16px;
  color: #fff;
  background-color: #666;
  border: none;
  width: fit-content;
  cursor: pointer;
  font-size: 0.9rem;  
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  transition: 0.3s ease all;
}

.destructive-button:hover {
  transition: 0.3s ease background-color;
  background-color: #fa0000;
}