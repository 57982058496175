.container{
    position: relative;
    overflow: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none;
    padding: 0px 20px;
}
.get-reports__container {
    
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.get-reports__content {
    margin-bottom: 170px;
}

.get-reports__content button {
    margin-top: 10px;
    padding: 10px;
    border-radius: 30px;
    border: none;
    color: #fff;
    width: 450px;
    font-size: 24px;
    font-weight: 600;
    background-color: #290AEC;

    cursor: pointer;
}

.get-reports__content button:hover {
    color: #290AEC;
    background-color: #fff;
    border: #290AEC 1px solid;
    padding: 9px;

    
}