.settings-container-header {
  position: relative;
  height: 100%;
  padding: 0px 20px;
  background-color: transparent;
}

/*
  TOGGLE THEME MODE
*/

.settings-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;


}


.account-settings h2 {
  font-size: 1.2rem;
  display: flex;
  color: #fff;
  justify-content: left;
}

.account-settings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}


/* Security & Verification Settings Page */

.security-settings-container {
  width: 100%;
  height: calc(100vh - 110px);
  position: relative;
}


.security-verification-wrapper {
  display: flex;
  gap: 10px;
  flex-direction: row;
  height: 100%;
  position: relative;
  justify-content: center;
}

.verification-container {
  align-items: center;
  height: 100%;
}


.security-container {
  flex: 0.5;
}

.security-container,
.verification-container {
  display: flex;
  flex-direction: column;
  margin-right: 0px;
  border-radius: 10px;
  position: relative;
  width: 100%;
  background: #1e1f21;
  /* background: linear-gradient(45deg, #191970, #00416A); */
  /* box-shadow: 0px 18px 30px 0px rgba(0, 0, 0, 0.39); */
  box-sizing: border-box;
  overflow: hidden;
}

.security-container h2,
.verification-container h2 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  text-align: left;
  border-radius: 10px;

}

.security-option,
.verification-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  background-color: #272B30;
  padding: 10px;
  border-radius: 4px;
}

.verification-option div,
.security-option div {
  flex: 0.9;
}


.data-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-wrap: wrap;
}

.data-section span {
  font-size: 0.8rem;
  color: #a0a0a0;
  overflow: auto;

}

.settings-options--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 8px;
}

.settings-options--wrapper.security {
  height: 60%;
}

.settings-options--wrapper.verification {
  height: 100%;
}

.security-options,
.verification-options {
  padding: 5px 15px 20px 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  justify-content: space-around;
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
}

.security-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.option-title {
  font-size: 0.9rem;
  font-weight: 500;
  color: #fff;
  margin: 0;
  margin-bottom: 10px;
}

.option-description {
  width: 330px;
  font-size: 0.8rem;
  color: #ccc;
  overflow: auto;
}


.device-management-container {
  margin-right: 0px;
  border-radius: 10px;
  background: #1e1f21;
  padding: 10px;
  box-sizing: border-box;
  height: 55%;
  min-height: 55%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
}

.device-management-container--header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 15px);
  padding: 0px 5px;
}

.device-management-container--p {
  color: #ccc;
  margin-bottom: 0.8rem;
  padding-left: 5px;
  margin-top: 0px;

}

.device-management-container h2 {
  width: fit-content;
  color: #fff;
  font-weight: 500;
  padding: 10px 0px;
  border-radius: 10px;
  margin: 0;
  font-size: 1.2rem;

}

.device-management-container h2,
.device-management-container h3 {
  align-self: stretch;
  text-align: left;
}

.dummy-devices {
  display: flex;
  width: 100%;
  scrollbar-width: thin;
  padding: 0px 5px;
  overflow-y: auto;
  flex-direction: column;

}

.dummy-device {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #272B30;
  color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
}

.device-tag {
  padding: 3px 5px;
  border-radius: 4px;
  background-color: #1e1f21;
  color: rgb(73, 240, 73) !important;
  width: fit-content;
}

.dummy-device-icon {
  width: 70px;
  margin-bottom: 5px;
}

.dummy-device-info span {
  font-weight: 500;
  font-size: 0.9rem;
}

.dummy-device-info p {
  font-size: 0.8rem;
  color: #ccc;
  margin: 2px 0px;
}

.dummy-device-info {
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 0.9;
}

.dummy-toggle {
  padding: 5px 15px;
  border: none;
  border-radius: 15px;
  background-color: #ffffff;
  color: #5F8E9E;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
}



/* Account and preferences */


.profile-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f2f2f2;
  /* Dummy background color */
  margin: 10px 0;
  position: relative;
  text-align: center;
  gap: 30px;
}

.profile-image-container {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #64d2ff;
  font-weight: bold;
  border-radius: 50%;
  background-color: #222;
  overflow: hidden;
  border: 1px solid #222;
}


.profile-image {
  border-radius: 50%;
  /* width: inherit;  */
  /* height: inherit; */
  /* object-fit: cover;  */
}

.profile-initials {
  align-items: center;
  font-size: 26px;
  margin: auto;
}


.profile-image-container .profile-image-input {
  display: block;
}

.profile-details h3,
.profile-details p {
  margin: 10px 0;
}

.save-button {
  cursor: pointer;
  background-color: #1779b1;
  border: 1px solid #1779b1;
  color: #fff;
  transition: 0.3s ease border;
}

.cancel-button {
  cursor: pointer;
  background-color: #555;
  border: 1px solid #555;
  color: #fff;
  transition: 0.3s ease all;
}

.profile-image-input.editing:hover {
  background-color: #e6e6e6;
}

.profile-image-input.editing[type="file"] {
  display: none;

}

.profile-image-input.editing+label {
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 6px;
}

.profile-image-input.editing+label:hover {
  background-color: #f0efef;
}


.profile-image-input {
  display: none;
}

label[for="profileImageInput"] {
  display: inline-block;
  padding: 5px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  background-color: #f1f1f1;
}

.reminder-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  height: 100%;
  border-radius: 10px;
  flex: 1;
  position: relative;
}




.reminder-options p {
  font-size: 0.9rem;
}

.reminder-options {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  margin: 0px 5px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  background-color: #272B30;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 0px 5px;
}

.checkbox-options h2 {
  padding: 10px;
  margin: 10px 0px;
  font-weight: 500;
  text-align: left;
  border-radius: 10px;
}

.reminder-options div {
  width: 100%;
  display: flex;
  flex-direction: row;

}



/* Personal Information Styles */

.personal-info-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  padding: 8px;
  background-color: #1e1f21;
  border-radius: 12px;
  height: 100%;
  transition: box-shadow 0.3s ease;
  overflow: auto;
}




.personal-info-section p {
  display: flex;
  font-size: 0.8rem;
  color: #000;
}

.personal-info__display {
  text-align: left;
  width: 90%;
  color: #fff;
  margin: 0px auto;
  font-weight: 500;
}

.personal-info__display span {
  font-weight: 500;
  font-size: 0.9rem;
}

.personal-info__display input {
  font-size: 0.8rem;
  color: #fff;
  background-color: #1e1f21;
  outline: 1px solid #444;
  border: none !important;
  margin-top: 10px;
}


.personal-information-container label {
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
  color: #1e1f21;
  text-align: start;
}

.personal-info-section input {
  outline: 1px solid #444;
  border-radius: 8px;
  background-color: #0e1013;
  padding: 8px;
  width: 100%;
  transition: ease 0.3s outline;
}

.personal-info-section input:focus {
  outline: 1px solid #64d2ff;
  transition: ease 0.3s outline;
  /* box-shadow: 0 0 4px #64d2ff; */
}

.personal-information-container {
  background-color: #1e1f21;
  /* Dummy background color */
  display: flex;
  position: relative;

  flex-direction: column;
  border-radius: 10px;
  padding: 5px 8px 15px 8px;
  flex: 1;
}

.personal-information-container h2 {
  padding: 10px;
  font-weight: 500;
  text-align: left;
  margin: 5px 0px;
  border-radius: 10px
}

.personal-information-container p {
  font-size: 18px;
  border-bottom: #ccc 1px solid;
}


.data-information-container button {
  padding: 10px 20px;
  margin: 10px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  /* background: linear-gradient(45deg, #644391, #30175B); */
  background: #1779b1;
  cursor: pointer;
}

.data-information-container button:hover {
  border: 2px solid #fff;
  transition: border 0.3s ease;
}


.address-section p {
  margin: 10px 0;
  color: #000;

}

.Buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}

.edit-button {
  display: flex;
  cursor: pointer;
  border: 1px solid #1779b1;
  transition: border 0.3s ease;

}



.checkbox-options {
  background-color: #1e1f21;
  border-radius: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.checkbox-options label {
  text-align: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  color: #fff;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  font-size: 0.9;
  font-weight: 500;
}

/* .checkbox-options input {
  width: 30px;
  margin: 0;
  cursor: pointer;
  background-color: #2196F3;
} */

.reminder-options label {
  display: flex;
}

.reminder-options input[type="checkbox"] {
  display: none;
}

.reminder-options label>input[type="checkbox"]+span::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: 2px solid #444;
  background-color: #444;
  border-radius: 8px;
  vertical-align: middle;
  transition: all ease 0.3s;
}

.reminder-options label>input[type="checkbox"]:checked+span::before {
  background-color: #64d2ff;
  border: 2px solid #64d2ff;
  transition: all ease 0.3s;
}

.reminder-options label>input[type="checkbox"]:checked+span::after {
  content: "";
  position: absolute;
  font-size: 28px;
  color: #64d2ff;
  z-index: 10;
  left: 4px;
  top: -7px;
}


.reminder-options label>span {
  position: relative;
  /* padding-left: 30px; */

}


.personal-billing__container {
  /* background: linear-gradient(45deg, #644391, #30175B); */
  background: #222;
  border-radius: 10px;
  padding: 0px 10px;
  flex: 0.4;
}

.personal-billing__container h2 {
  color: #fff;
  font-weight: 500;

}

.personal-billing__info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.personal-billing__row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;

}

.personal-billing__row p {
  margin: 5px;
  color: #fff;

}

.personal-billing__row span {
  color: #64d2ff;
  background-color: #444;
  border-radius: 4px;
  padding: 3px 8px;
  font-weight: 500;
  text-align: center;

}

.billing-nav__btn {
  padding: 3px 20px;
  border-radius: 20px;
  text-align: center;
  border: none;
  font-weight: 500;
  background: #fff;
  width: fit-content;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
  border: 2px solid #fff;
  transition: border 0.3s ease;
}

.billing-nav__btn:hover {
  border: 2px solid #64d2ff;
  transition: border 0.3s ease;
}

.billing-nav__btn p {
  text-decoration: none;
  margin: 0;
  color: #1779b1;
}

.personal-billing__info--div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.personal-billing__info--card {
  text-align: center;
  background-color: #444;
  border-radius: 10px;
  display: flex;
  padding: 20px;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.personal-billing__info--card p {
  font-weight: 500;
  margin: 0;
  color: #64d2ff;
}


.info-preferences-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;



}

.account__combined-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
}

.account__left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.account__right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



/* Custom File Upload */
/* .custom-file-upload {
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-upload-label {
  background-color: #007bff;
  color: #fff;
  padding: 10px;

  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.file-upload-label p {
  color: #FFF;
  margin: 0;
  border: none;

}

.file-upload-label:hover {
  background-color: #0056b3;
} */

.custom-file-upload {
  padding: 0px 5px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.file-upload-label {
  /* padding: 8px 12px; */
  /* background-color: #4CAF50; */
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  /* transition: background-color 0.3s; */
  text-align: center;
  margin: 0;
  font-size: 8px;
  font-weight: 400;
}


.custom-file-upload span {
  /* margin-top: 10px; */
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  width: 100%;
  max-width: 100px;
  /* border: 1px solid #e0e0e0; */
  padding: 5px;
  border-radius: 3px;
  /* background-color: #f7f7f7; */
}

.image-preview {
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



/*  Finance Settings  */


.section-information {
  color: #fff;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.finance-settings__section {
  border-radius: 10px;
  background-color: #3b3b3b;
  height: 100%;
  position: relative;
}

.finance-settings__section.half {
  height: 40%;
}

.finance-settings__section.quater {
  height: 33%;
}

.finance-settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
}

.finance-settings-header h2 {
  font-size: 26px;
  margin: 0;
  border-radius: 10px;
  text-align: center;
  color: #fff;
}

.finance-settings.finance-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.finance-usage-details {
  width: 100%;
  border-collapse: separate;
  border-spacing: 5px 5px;

  border-radius: 10px;
}

.finance-usage-detail__label {
  text-align: left !important;
  padding: 10px;
  font-weight: 500;
  color: #ddd;
}

.finance-usage-detail__tag {
  padding: 3px 10px;
  background-color: #222;
  color: #64d2ff;
  font-weight: 500;

}

.finance-usage-details thead th {
  border-radius: 5px;
  border: none;
  text-align: left;
}

.finance-usage-details tbody td {
  text-align: center;
  border-radius: 5px;
  border: none;
}


.finance-planamount__row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.finance-planamount__column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.finance-plan--amount {
  font-size: 16px;
  background-color: #222;
  padding: 3px 10px;
  border-radius: 6px;
  font-weight: 500;
  color: #64d2ff !important;
}

.finance-planamount__column p {
  margin: 0;
}


.finance-planamount__row .finance-section.subscription {
  width: 80%;
  margin: 0px auto;
  border-radius: 10px;
  background-color: #222;
}

.finance-section.subscription div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px auto;
  gap: 20px;

}

.plan-details {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.pricing-header {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.pricing-header p {
  padding: 3px 10px;
  background-color: #666;
  color: #64d2ff;
  font-weight: 500;
  margin: 0;
  border-radius: 4px;
  background-color: green !important;
  margin: 0;
  font-size: 20px !important;
}

.finance-settings-header h4 {
  font-size: 22px;
  margin: 0px;
}

.finance-pricing-plan__contanier {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}


.features-includes-container {
  display: flex;
  flex-direction: column;
  gap: 5px;

}

.features-includes-container span {
  font-size: 24px !important;
  font-weight: 500;

}

.features-list,
.includes-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
  padding: 5px;
  border-radius: 10px;
  height: 16vh;
  overflow-y: auto;
  width: 98%;

  background-color: #666;
}

.features-list li {
  list-style: none;
  font-size: 14px !important;
}


.plan-amount-details {
  margin: 5px 0;
}

.pricing-description {
  font-style: italic;
  height: 50%;
  border-radius: 10px;
  margin-top: auto;
  font-size: 18px !important;
  background-color: #666;
}

.feature-item,
.include-item {
  display: flex;
  font-size: 14px !important;
  justify-content: flex-start !important;
  text-align: left !important;
}

.feature-item::before,
.include-item::before {
  content: '✓';
  color: #64d2ff;
  margin-right: 10px;
}


.finance-settings {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
  width: calc(100vw - 280px);
  align-items: center;

}

.finance-section {
  padding: 10px;
  position: relative;
  height: 100%;
}

.section-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex: 1;
  width: 100%;
}


.finance-settings p {
  font-size: 0.9rem;
  margin-bottom: 10px;
  color: #fff;
  margin: 0;
}

.finance-settings p span {
  font-weight: 400;
}

.finance-settings ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}

.finance-settings li {
  margin: 5px 0;
  font-size: 16px;
  font-weight: 500;
}

.finance-settings button {
  padding: 3px 20px;
  /* background: linear-gradient(45deg, #644391, #30175B); */
  background: #1779b1;
  color: white;
  border: 2px solid #1779b1;
  border-radius: 30px;
  font-weight: 500;
  cursor: pointer;
  transition: border 0.2s ease;
  align-self: center;
}

.finance-settings button:hover {
  border: 2px solid #fff;
  transition: border 0.2s ease;
}

.finance-settings ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.finance-settings ul li {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

}

.stretch-to-end {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.payment-info__section {
  display: flex;
  flex-direction: row;
  gap: 50px;
  flex: 1;
}


.add-payment-method-container {
  display: flex;
  justify-content: flex-end;
}



.payment-list {
  background-color: #222;
  padding: 5px;
  border-radius: 10px;
}

.payment-method-card {
  padding: 10px;
  border-radius: 10px;
  background-color: #222;
  border: 2px solid #222;
  transition: 0.3s ease border;
  cursor: pointer;
  flex: 1;
  min-width: 200px;
  width: 200px;
  max-width: 200px;
  position: relative;
}

.cover-btn{
  
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* opacity: 0.5; */
  z-index: 100;
  width: 100%;
}
.complete-onboarding-btn{
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center horizontally and vertically */
  width: 90%;
  padding: 0px 10px;
}

.payment-method-no-account-card {
  padding: 10px;
  border-radius: 10px;
  background-color: #222;
  border: 2px solid #222;
  transition: 0.3s ease border;
  /* margin-bottom: 10px; */
  cursor: pointer;
  display: flex;
  min-width: fit-content;
  width: 200px;
  max-width: 200px;
  align-self: center;
  margin: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: 0.5;
}

.payment-method-no-account-card .fa-circle-info {
  color: #444;
  font-size: 40px;
}

.payment-method-card:hover {
  border: 2px solid #64d2ff;
  transition: 0.3s ease border;
}

.method-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
}

.payment-method-card.payment-selected {
  background-color: #444;
  border: 2px solid #64d2ff;
  transition: 0.3s ease border;
}

.method-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.method-details {
  font-size: 0.9em;
  color: #ccc;
}



.payment-methods-list {
  min-width: 80%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;

}

.set-default-button,
.default-method,
.remove-method-button {
  width: 130px;
  text-align: center;
  padding: 0;
}

.default-method {
  background-color: #000;
  color: white;
  padding: 5px 8px;
  border-radius: 15px;
  font-size: 16px;
  margin: 0px;
  width: 80px !important
}


.finance-section.payment-info {

  /* max-width: 50%; */
}


.finance-credits__tag {
  padding: 5px;
  border-radius: 5px;
  background-color: #222;
  color: #64d2ff;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.finance-credits__tag span {}

.finance-credits__tag p {
  margin: 0;
}

.finance-credits__tag--container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-self: center;
  margin-bottom: 20px;
}

.usage-details,
.top-off-options {
  background-color: #3b3b3b;
  border-radius: 20px;
  padding: 15px;
  margin: 10px 0;
  color: #fff;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.usage-details h3,
.top-off-options h3 {
  color: #64d2ff;
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
}

.usage-details ul,
.top-off-options ul {
  list-style: none;
  padding: 10px;
}


.usage-details li,
.top-off-options li {
  background-color: #222;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  align-items: center;

}

.usage-details li span,
.top-off-options li span {
  color: #ccc;
  font-size: 16px;
}

.top-off-options button {
  padding: 5px 15px;
  background: #1779b1;
  color: white;
  margin-top: 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.top-off-options button:hover {
  background-color: #0056b3;
}


.finance-pricing__card {
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: space-between;
  padding: 5px;
  border-radius: 8px;
  margin: 5px;
  align-items: center;
  border: 2px solid #222;
  background-color: #222;
}

.finance-pricing__card p {
  margin: 0;

}

.finance-pricing__card--end {
  display: flex;
  align-items: center;
  gap: 10px;
}

.finance-pricing__card--end p {
  padding: 3px 10px;
  background-color: green;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;
}

.billing-scroll-list {
  overflow-y: auto;
  max-height: 80%;
}


/* AI SETTINGS */

.ai-settings-container {
  background-color: #1e1f21;
  color: #fff;
  border-radius: 8px;
  width: 100%;
}

.ai-settings-title {
  color: #fff;
  font-weight: 500;
  margin: 15px;
  font-size: 1.2rem;
  text-align: left;
  margin-bottom: 20px;
}

.ai-settings-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

.ai-settings-field--div {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.ai-settings-field--span {
  font-size: 0.8rem;
  color: #ccc;
}

.ai-settings-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ai-settings-label {
  color: #fff;
  font-size: 0.9rem;
}

.ai-settings-input {
  background-color: #444;
  color: #fff;
  border: 1px solid #64d2ff;
  border-radius: 4px;
  padding: 10px;
  font-size: 0.9rem;
}

.ai-settings-button {
  background-color: #1779b1;
  color: #fff;
  border: 2px solid #1779b1;
  transition: 0.3s ease border;
  border-radius: 30px;
  padding: 8px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  align-self: center;
}

.ai-settings-button:hover {
  border: 2px solid #fff;
  transition: 0.3s ease border;
}

/* Notifications */

.notification-settings-container {
  background-color: #1e1f21;
  color: #fff;
  flex: 1;
  border-radius: 8px;
}

.notification-settings-title {
  color: #fff;
  font-size: 1.2rem;
  margin: 20px 20px 0px 20px;
  font-weight: 500;
  text-align: left;
}

.notification-settings-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
}

.notification-settings-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.notification-settings-label {
  color: #ccc;
  font-size: 14px;
}

.notification-settings-toggle {
  position: relative;
  width: 60px;
  height: 34px;
}

.notification-settings-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.notification-settings-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #444;
  border-radius: 34px;
  transition: 0.4s;
}

.notification-settings-checkbox:checked+.notification-settings-slider {
  background-color: #64d2ff;
}

.notification-settings-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.notification-settings-checkbox:checked+.notification-settings-slider:before {
  transform: translateX(26px);
}


/* COMPANY SETTINGS */

.company-settings-container h2 {
  font-weight: 500;
  margin: 0px;
}

.company-settings-container {
  background: #1e1f21;
  padding: 15px;
  border-radius: 8px;
  color: #fff;
  height: calc(100vh - 140px);
  position: relative;
}

.company-settings-container__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  justify-content: space-between;
  width: 100%;
}

.company-settings-content {
  display: flex;
  width: 100%;
  position: relative;
  margin-top: 10px;
  flex-direction: column;
  height: 90%;
  justify-content: space-between;
}

.company-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  position: relative;
}

.company-info--grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
  position: relative;
}

.company-settings-container__header--row p {
  font-size: 1rem;
  color: #fff;
  background: radial-gradient(#000, #222);
  padding: 3px 8px;
  border-radius: 4px;
  margin: 0;
  font-weight: 500;
}

.company-settings-container__header--row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.company-photo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.photo-label.normal:hover {
  background-color: transparent;
}

.photo-label.normal {
  border: 2px solid transparent;
  background-color: transparent;
}

.photo-label {
  display: block;
  max-width: 250px;
  position: relative;
  max-height: 100px;
  padding: 5px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  border: 2px dashed #ccc;
  background-color: #222;
  color: #ccc;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: auto;
}

.photo-label:hover {
  background-color: #444;
  color: #fff;
}

.photo-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.photo-placeholder i {
  font-size: 24px;
}

.photo-placeholder p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
}

.photo-upload input[type="file"] {
  display: none;
}

.photo-preview {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100px;
  /* Set max height to avoid overly large images */
  object-fit: contain;
  /* Scale the image while maintaining aspect ratio */
  border-radius: 8px;
  margin-left: auto;
}

.company-photo img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 150px;
  object-fit: contain;
  border-radius: 8px;
  margin-left: auto;
}

.company-info-field {
  margin-bottom: 15px;
  position: relative;
}

.company-info-field label {
  display: block;
  font-size: 0.9rem;
  width: 100%;
  position: relative;
  margin-bottom: 5px;
  color: #ccc;
}

.company-info-field p,
.company-info-field input,
.company-info-field textarea {
  padding: 10px;
  background: #0e1013;
  border-radius: 4px;
  color: #fff;
  font-size: 0.8rem;
  border: none;
  outline: 1px solid #444;
  transition: 0.3s ease outline;
}

.company-info-field textarea {
  width: 97%;
}

.company-info-field input {
  width: 95%;
}

.company-info-field input:focus,
.company-info-field textarea:focus {
  outline: 1px solid #64d2ff;
  transition: 0.3s ease outline;
}

.company-info-field input,
.company-info-field textarea {
  outline: 1px solid #444;
  transition: 0.3s ease outline;
  border: none;
}

.company-settings-actions {
  margin-top: 20px;
}

.company-settings-actions button {
  background: #1779b1;
  color: #fff;
  padding: 5px 20px;
  border: 2px solid #1779b1;
  transition: 0.3s ease border;
  border-radius: 30px;
  cursor: pointer;
}

.company-settings-actions button:hover {
  border: 2px solid #fff;
  transition: 0.3s ease border;
}