
.center_panel {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: auto;
    background: #333;
    transition: ease-in-out 0.3s;
    z-index: 100;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.5);
    transform: translate(-50%, -50%);
    padding: 20px;
    z-index: 1;
}

.center_panel.open_panel {
    display: none;
}
.center_panel hr{
    opacity: 0.2;
}
.center_panel .header-sec{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.center_panel .header-sec i{
   font-size: 100px;
   position: absolute;
   left: 0;
   top: 0px;
   color: #64d2ff;
}

.center_panel .header-sec h1{
    font-size: 40px;
    margin: 0;
    margin-bottom: 20px;
}

.pay-close-btn {
    position: absolute;
    top: 0;
    right: -10px;
    border-radius: 50%;
    padding: 0;
}

.main-section-payment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.user-info-sec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 500px;
    gap: 5px;
}

.user-info-sec p {
    color: #f6e5e5;
}


.user-info-sec p>i {
    font-size: 18px;
    margin-right: 10px;
    color: #63fbc1;
    -webkit-text-stroke: 1px #13f09b;
}

.user-info-sec  .details-sec{    
    width: 100%;
    height: 230px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.user-info-sec  .details-sec .feature-container{
    overflow: auto;
    scrollbar-width: none;
    height: 210px;

}

.user-info-sec  .details-sec .plan-btn{
    margin: auto;
}

.user-info-sec  .details-sec img{
    width: 100px;
    height: 100px;
}

.user-info-sec  .details-sec .li-item{
    color: #fff;
}

.user-info-sec .subscribe-btn{
    width: 100% !important;
    border-radius: 5px !important;
    font-size: large;
    background-color: #08dfee !important;
    color: #fff;
    font-weight: 700;
    border: none !important;
}

.user-info-sec .term-condition-detail{
    font-size: 12px;
    text-align: start;
    text-wrap: wrap;
    color: #fff;
}

.user-info-sec .term-condition-detail .link{
    color: #2de1b1 !important;
}

.email-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

}

.email-sec {
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #fff;
    width: 320px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

}

.email-sec i {
    font-size: 18px;
    color: #666
}

.email-sec input {
    background: transparent;
    border: none;
    width: 290px;
    color: #fff;
    padding: 3px 0px;
    outline: none;
    box-shadow: none;
}

.email-sec input:focus {
    outline: none;
    border: none;
}

.change-email-btn {
    padding: 7px 10px !important;
    border-radius: 7px !important;
    text-transform: uppercase !important;
    background-color: transparent !important;
    border: 1px solid #eae8e8 !important;
    color: #fff;
}


.payment-detail-sec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
    padding-left: 15px;
    gap: 7px;
}

.payment-detail-sec p{
    font-size: 20px;
    color: #f6f3f3;
    padding: 0;
}

.package-details{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.package-details .plan-name{
    color: #ababab;
    font-size: 16px;
    width: 250px;
    text-wrap: wrap;
}

.package-details .plan-amount{
    color: #ababab;
    font-size: 16px;
}



.payment-detail-sec .overall-details{
    width: 100%;
}

.payment-detail-sec .overall-details p {
    font-size: 14px;
    text-align: end;
    color: #0be6ee;
}


.payment-detail-sec  .overall-details .package-detail{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%
}

.payment-detail-sec  .overall-details .package-detail .total{
    font-size: 18px;
    color: #fff !important;

}

.payment-detail-sec img{
    width: 250px;
    height: 150px;
    margin: auto;
}

li{
    /* color: #fff !important; */
}