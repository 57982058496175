.user-type-heading {
    text-align: center;
    font-size: 40px;
    color: #fff;
    margin: 0;
    font-weight: bold;
}

.user-type-message {
  color: #ccc;
  font-size: 20px;
  text-align: center;
}

.user-help-link{
    display: block;
    text-align: center;
    color: #64d2ff;
    margin: auto;
}

.checkbox-container a {
    color: #fff; 
    text-decoration: none !important;
  }

  .pricing-sale-tag {
    background-color: green;
    padding: 2px 3px;
    width: fit-content;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;
    text-align: center;
  }
  


.user-help-link  a:visited {
    color: #fff; 
    text-decoration: none !important;
  }
  
  .user-help-link  a:hover {
    color: #ccc; 
    text-decoration: underline; 
  }
  
  .user-help-link  a:active {
    color: #ccc; 
  }

.pricing-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
    margin-top: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.pricing-container::-webkit-scrollbar {
    display: none;
}

.pricing-card {
    position: relative;
    flex: 0 0 auto;
    cursor: pointer;
    border: 2px solid #222;
    border-radius: 8px;
    width: 300px;
    padding: 20px;
    min-height: 250px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #222;
    color: #fff;
    transition: border ease 0.3s;
}

.pricing-card.wide {
  width: 500px;
}

.pricing-card.selected {
    border: 2px solid #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.pricing-card.active,
.pricing-card:hover {
    border: 2px solid #fff;
    transition: border ease 0.3s;
    /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); */
}

.pricing-price-wrapper {
  position: absolute;
  top: -2px;
  right: -2px;

}

.pricing-card h3 {
    margin: 0;
    padding: 5px 10px;
    border-radius: 0 8px 0 8px;
    background-color: green;
    text-align: center;
    color: white;
    font-size: 18px;
}

.pricing-top__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pricing-old-price, .pricing-annual-old {
  text-decoration: line-through;
  color: #888; 
  font-size: 0.9rem; 
}

.pricing-savings {
  color: #ddd;
  font-weight: bold;
  background-color: #444;
  border-radius: 5px;
  padding: 2px 5px;
  margin-right: 2px;
  margin-left: 10px;

  font-size: 0.8rem;
}

.price {
    font-size: 16px;
    font-weight: bold;
    padding: 3px 8px;
    margin: 0;
    align-self: flex-start;
    border-radius: 4px;
    width: fit-content;
    background-color: #444;
    color: #64d2ff;
}


.pricing-title__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0px 0px 40px 0px;
}

.pricing-icon {
  font-size: 24px;
  color: #64d2ff;

}

.toggle-switch__pricing.month-year {
    width: 250px;
    align-self: flex-end;
    height: 20px;
}

.toggle-switch__pricing {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #222;
    border-radius: 20px;
    padding: 5px;
    width: 450px;
    height: 30px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }

  .slider-otp-pricing {
    position: absolute;
    height: 100%;
    width: 50%;
    background-color: #1b8ed0;
    border-radius: 20px;
    right: 50%;
    transition: all 0.3s ease;
  }
  
  .toggle-switch__pricing button {
    flex: 1;
    text-align: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
    transition: color 0.3s ease;
    font-weight: 600;
    color: #a3a0a0;
    z-index: 2;
  }

.toggle-switch__pricing.month-year button {
  font-size: 14px;
}
  
  .toggle-switch__pricing button p {
    margin: 0;
    transition: all 0.3s ease;
  }
  
  .toggle-switch__pricing button.active {
    color: white;
    transition: all 0.3s ease;
  }
  
  .slider-otp-pricing.right {
    right: 0;
  }
  
  .left {
    left: 0;
  }
  
  
  .pricing-plan-description {
    color: #fff;
    margin: 0;
    padding: 5px;
    background-color: #444;
    border-radius: 4px;
    margin-top: 10px;
    height: 40%;
  }

.pricing-free-trail {
    padding: 3px 8px;
    background-color: #fff;
    color: #222;
    text-align: center;
    border-radius: 4px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-self: flex-end;
    margin-bottom: 10px;
}

.pricing-header span {
  font-size: 1rem;
}

.pricing-header h2 {
  font-size: 2.5rem;
  margin-top: 50px;
  font-weight: 500;

}

.pricing-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.pricing-services__header {
    font-weight: 600;
    color: #64d2ff;
    font-size: 20px;
    
}

.pricing-one-time__row {
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: 4px;
    background-color: #555;
    justify-content: flex-start;
    align-items: center;
}

.pricing-cases__title strong {
  font-size: 20px;
  color: #64d2ff;

}

.pricing-cases__title {
    display: flex;
    justify-content: center;
}

.pricing-row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 10px;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* ul li {
 
 
    padding: 5px 10px;
    border-radius: 4px;
} */