.payment-modal {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(221, 211, 211, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 10000;
    opacity: 1;
}

.payment-modal-content {
    background-color: #3b3b3b;
    position: relative;
    padding: 20px;
    padding-top: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;

}

.payment-modal.show {
    display: flex;
}


.close-button {
    position: absolute;
    color: #fff;
    padding: 5px !important;
    background-color: #64d2ff !important;
    border-radius: 50% !important;
    right: -5px;
    top: -5px;
    width: 30px;
    height: 30px;
    text-align: center;
    
}

.close-button i{
    font-size: 18px;
}


.icon-wrapper {
    --fa-primary-color: #000333;
    /* Primary color */
    --fa-secondary-color: #1b8ed0;
    /* Secondary color */
    --fa-secondary-opacity: 1;
    /* Secondary opacity */
}
