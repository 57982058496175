.result-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align: center;
    max-width: 500px;
    margin: 50px auto;
    animation: fadeIn 0.5s ease-in-out;
}

.result-tick {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    animation: crossAnimation 1s ease-in-out forwards;
}

.result-tick__circle {
    stroke: #f44336;
    stroke-width: 5;
}

.result-tick__cross {
    stroke: #f44336;
    stroke-width: 5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 40;
    stroke-dashoffset: 40;
    animation: crossDraw 1s ease-in-out forwards;
}

.payment-details {
    text-align: left;
    margin-top: 20px;
}

.payment-details h2 {
    margin-bottom: 10px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes crossDraw {
    from {
        stroke-dashoffset: 40;
    }
    to {
        stroke-dashoffset: 0;
    }
}
