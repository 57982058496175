.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* height: 100vh;/ */
    max-width: 500px;
    margin: 50px auto;
    animation: fadeIn 0.5s ease-in-out;
    border: 1px solid #ccc;
}

.success-tick {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

.success-tick__circle {
    stroke: #4caf50;
    stroke-width: 2;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-linecap: round;
    animation: draw-circle 0.6s ease-in-out forwards;
}

.success-tick__check {
    stroke: #4caf50;
    stroke-width: 2;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    stroke-linecap: round;
    animation: draw-check 0.3s 0.6s ease-in-out forwards;
}

@keyframes draw-circle {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes draw-check {
    to {
        stroke-dashoffset: 0;
    }
}

.success-container h1 {
    color: #4caf50;
    margin-bottom: 10px;
}

.success-container p {
    color: #333;
    margin: 5px 0;
}

.payment-details {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    width: 100%;
    max-width: 400px;
}

.payment-details h2 {
    margin-bottom: 10px;
    color: #333;
}

.payment-details p {
    color: #555;
    margin: 5px 0;
}

.payment-details strong {
    color: #000;
}
